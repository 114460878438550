var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loading && _vm.dataReady
      ? _c(
          "div",
          { staticClass: "animated fadeIn" },
          [
            _c(
              "b-card",
              { attrs: { "body-class": "p-0" } },
              [
                _c(
                  "b-card-header",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "8" } }, [
                          _c("h4", { staticClass: "mt-2" }, [
                            _vm._v("Histórico de Locações"),
                          ]),
                        ]),
                        !_vm.activeBooking && _vm.driver.status != "BANNED"
                          ? _c(
                              "b-col",
                              {
                                staticClass: "text-right mt-1",
                                attrs: { cols: "4" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goTo(null)
                                      },
                                    },
                                  },
                                  [_vm._v(" Nova locação ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card-body",
                  { attrs: { "body-class": "p-0" } },
                  [
                    _c("b-table", {
                      attrs: {
                        responsive: "",
                        striped: "",
                        hover: "",
                        items: _vm.bookings.items,
                        fields: _vm.fields,
                      },
                      on: { "row-clicked": _vm.goTo },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(plan)",
                            fn: function (data) {
                              return [
                                _c("strong", [
                                  _vm._v(_vm._s(data.item.plan.name)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(amount)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setPriceFormat(
                                        data.item.transaction_amount
                                      )
                                    ) +
                                    " "
                                ),
                                data.item.transaction_discount > 0
                                  ? _c(
                                      "span",
                                      { staticClass: "small text-muted" },
                                      [
                                        _vm._v(
                                          "(Desc. " +
                                            _vm._s(
                                              _vm.setDiscountFormat(
                                                data.item.transaction_discount
                                              )
                                            ) +
                                            ")"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell(kind_open)",
                            fn: function (data) {
                              return [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getOpenKindById(
                                          data.item.kind_opened_id
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(car)",
                            fn: function (data) {
                              return [
                                data.item.car && data.item.car !== null
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "b-link",
                                          {
                                            attrs: {
                                              title: _vm.getCarInfos(
                                                data.item.car
                                              ),
                                              variant: "link",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.goToCar(
                                                  data.item.car.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.item.car.license_plate ||
                                                    " --- "
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("span", [
                                      data.item.status === "CLOSED" ||
                                      data.item.status === "CANCELED"
                                        ? _c("span", [_vm._v("-")])
                                        : _c("span", [_vm._v("Aguardando...")]),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "cell(started_at)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        data.item.started_at,
                                        "DD/MM/YYYY"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          _vm.$regions.isBrazil() &&
                          _vm.groups.includes("SQUAD-FLEET")
                            ? {
                                key: "cell(data_inicio)",
                                fn: function (data) {
                                  return [
                                    _vm.findStatusBooking(data.item.id)
                                      .data_inicio
                                      ? _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.findStatusBooking(
                                                      data.item.id
                                                    ).data_inicio,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [_vm._v("-")]
                                        ),
                                  ]
                                },
                              }
                            : null,
                          {
                            key: "cell(finished_at)",
                            fn: function (data) {
                              return [
                                data.item.finished_at
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("moment")(
                                            data.item.finished_at,
                                            "DD/MM/YYYY"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                          _vm.$regions.isBrazil() &&
                          _vm.groups.includes("SQUAD-FLEET")
                            ? {
                                key: "cell(data_fim)",
                                fn: function (data) {
                                  return [
                                    _vm.findStatusBooking(data.item.id).data_fim
                                      ? _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.findStatusBooking(
                                                      data.item.id
                                                    ).data_fim,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [_vm._v("-")]
                                        ),
                                  ]
                                },
                              }
                            : null,
                          _vm.$regions.isBrazil() &&
                          _vm.groups.includes("SQUAD-FLEET")
                            ? {
                                key: "cell(data_validacao)",
                                fn: function (data) {
                                  return [
                                    _vm.findStatusBooking(data.item.id)
                                      .data_validacao
                                      ? _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.findStatusBooking(
                                                      data.item.id
                                                    ).data_validacao,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [_vm._v("-")]
                                        ),
                                  ]
                                },
                              }
                            : null,
                          _vm.$regions.isBrazil() &&
                          _vm.groups.includes("SQUAD-FLEET")
                            ? {
                                key: "cell(data_criacao)",
                                fn: function (data) {
                                  return [
                                    _vm.findStatusBooking(data.item.id)
                                      .data_criacao
                                      ? _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.findStatusBooking(
                                                      data.item.id
                                                    ).data_criacao,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [_vm._v("-")]
                                        ),
                                  ]
                                },
                              }
                            : null,
                          {
                            key: "cell(duration)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getDuration(data.item)) +
                                    " dias "
                                ),
                              ]
                            },
                          },
                          _vm.$regions.isBrazil()
                            ? {
                                key: "cell(indicacao_infracao)",
                                fn: function (data) {
                                  return [
                                    !_vm.findStatusBooking(data.item.id).status
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-spinner", {
                                              staticClass: "align-middle",
                                              attrs: { small: "" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.left",
                                                value: _vm.findStatusBooking(
                                                  data.item.id
                                                ).description,
                                                expression:
                                                  "findStatusBooking(data.item.id).description",
                                                modifiers: {
                                                  hover: true,
                                                  left: true,
                                                },
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "b-badge",
                                              {
                                                attrs: {
                                                  variant:
                                                    _vm.findStatusBooking(
                                                      data.item.id
                                                    ).variant,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.findStatusBooking(
                                                        data.item.id
                                                      ).status
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              }
                            : null,
                          {
                            key: "cell(status)",
                            fn: function (data) {
                              return [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.html",
                                        modifiers: { html: true },
                                      },
                                    ],
                                    attrs: {
                                      title: _vm.mountOperationInfoBooking(
                                        data.item
                                      ),
                                    },
                                  },
                                  [
                                    _c("b-badge", [
                                      _c("i", {
                                        staticClass: "fa fa-info-circle ml-1",
                                      }),
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(kind_close)",
                            fn: function (data) {
                              return [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getCloseKindById(
                                          data.item.kind_closed_id
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _vm.bookings.errorType || _vm.bookings.items.length === 0
                  ? _c(
                      "b-card-body",
                      { staticClass: "p-0" },
                      [
                        _c("content-controll", {
                          attrs: { service: _vm.bookings },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("modal-booking", {
              attrs: {
                modal_data: _vm.modal_data,
                allBookings: _vm.bookings.items,
              },
            }),
            _c("modal-docs-upload"),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "animated fadeIn" },
          [_c("content-loading")],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }