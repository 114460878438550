var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    [
      _c(
        "b-form-group",
        [
          _vm.lastClosedBookingKind != null
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    {
                      attrs: {
                        title: _vm.$i18n.t(
                          "modalBooking.texts.lastBookingResume"
                        ),
                      },
                    },
                    [
                      _c("b-card-text", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t(
                                "modalBooking.texts.lastClosedBookingResume.kind"
                              )
                            ) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.lastClosedBookingKind.translate[_vm.region]
                            ) +
                            " "
                        ),
                      ]),
                      _c("b-card-text", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t(
                                "modalBooking.texts.lastClosedBookingResume.finishedDate"
                              )
                            ) + ": "
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dateFormat(
                                _vm.lastClosedBookingKind.finished_at
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("b-card-text", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t(
                                "modalBooking.texts.lastClosedBookingResume.finishedInDays"
                              )
                            ) + ": "
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getDaysFrom(
                                _vm.lastClosedBookingKind.finished_at
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("b-card-text", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t(
                                "modalBooking.texts.lastClosedBookingResume.plan"
                              )
                            ) + ": "
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(_vm.lastClosedBookingKind.plan.name) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(_vm.lastClosedBookingKind.plan.description) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _vm.useLastPlan &&
                      _vm.lastClosedBookingKind.plan.status !== "RETIRED"
                        ? _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "checkbox-use-lastplan",
                                name: "checkbox-use-lastplan",
                                value: _vm.lastClosedBookingKind.plan,
                                "unchecked-value": {},
                              },
                              model: {
                                value: _vm.checkedValue,
                                callback: function ($$v) {
                                  _vm.checkedValue = $$v
                                },
                                expression: "checkedValue",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "modalBooking.texts.useLastBookingPlan"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-card",
                [
                  _c("b-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$i18n.t(
                            "modalBooking.texts.driverHaveNotPreviousBooking"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }