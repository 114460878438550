<template>
  <div>
    <multiselect
      label="text"
      placeholder="Selecione um carro"
      track-by="value"
      :close-on-select="true"
      :disabled="!filteredCars.length || disabled"
      :multiple="false"
      :options="filteredCars"
      :value="findSelectedItem"
      @input="emitValue"
      class="mb-1"
    />
    <b-alert :show="displayAlert" variant="warning">Esse carro não pertence ao plano selecionado</b-alert>
    <b-alert>{{ kindOpened }}</b-alert>
  </div>
</template>
<script>
import CARS_LIST_AVAILABLE from '@graphql/car/queries/list-available.gql';
import CARS_BY_FLAG from '@graphql/car/queries/cars-by-flag.gql';
import { subtractMonthAndYear } from '@utils/date';

export default {
  name: 'CarByCategorySelect',
  props: {
    value: {
      required: true,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    category: {
      required: false,
      default: undefined,
    },
    planCategory: {
      required: false,
      default: undefined,
    },
    planProductType: {
      required: false,
      default: undefined,
    },
    plan: {
      required: false,
      default: undefined,
    },
    kindOpened: {
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    carSelected: undefined,
    cars: [],
    carsAvailable: [],
    loading: false,
    filteredCars: [],
    displayAlert: false,
    kp2FlagId: '549c2dfc-4bab-4803-be07-f247446e3c7e',
  }),
  async beforeMount() {
    await this.fetchData();
  },
  computed: {
    findSelectedItem() {
      const selected = this.value;
      const available = this.filteredCars;

      return selected && available.length ? available.find(item => item.value === selected) : null;
    },
  },
  watch: {
    category: {
      handler(newCategory) {
        this.createFilteredCarsList(newCategory, this.planProductType);
        this.displayAlert = false;
        this.emitValue({ value: null });
      },
      immediate: true,
    },
    carSelected(newCar) {
      const carFromList = this.cars?.find(car => car.id === newCar);
      this.displayAlert = this.planCategory && carFromList && this.planCategory !== carFromList.category;
    },
    planProductType: {
      handler(newPlanProductType) {
        this.createFilteredCarsList(this.category, newPlanProductType);
      },
      immediate: true,
    },
    kindOpened: {
      handler() {
        this.createFilteredCarsList(this.category, this.planProductType);
      },
      immediate: true,
    },
  },
  methods: {
    getCarsFilteredByFlag() {
      const maxRemainingPeriod = 30;
      const positiveSpread = 5;
      const negativeSpread = 2;
      const contractPeriod = this.plan?.purchase_option_period;

      return this.carsAvailable.filter(car => {
        const { mobilized_at } = car;
        const today = new Date();
        const monthDiff = subtractMonthAndYear(today, new Date(mobilized_at));
        const carRemainingPeriod = contractPeriod - monthDiff;
        const contractPeriodMin = contractPeriod - negativeSpread;
        const contractPeriodMax = contractPeriod + positiveSpread;

        return (
          (contractPeriod === maxRemainingPeriod && carRemainingPeriod > maxRemainingPeriod) ||
          (carRemainingPeriod >= contractPeriodMin && carRemainingPeriod <= contractPeriodMax)
        );
      });
    },
    async fetchData() {
      await this.getCarsAvailableByFlag(this.kp2FlagId);
      await this.getCars();
    },
    async getCars() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: CARS_LIST_AVAILABLE,
        });
        if (data.cars) {
          this.cars = data.cars.items;
          this.createFilteredCarsList(this.planCategory, this.planProductType);
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    async getCarsAvailableByFlag() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: CARS_BY_FLAG,
          variables: { flagId: this.kp2FlagId },
        });

        if (data.carsByFlag) {
          this.carsAvailable = data.carsByFlag.items;
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    emitValue({ value }) {
      this.$emit('input', value);
      this.carSelected = value;
    },
    getCarsByCategory(cars, category) {
      return cars.filter(car => car.category === category);
    },
    mappedCars(cars) {
      return cars.map(car => ({
        value: car.id,
        text: `${car.license_plate} - ${car.brand?.name || ''} ${car.model?.name || ''}`,
      }));
    },
    createFilteredCarsList(category, flag) {
      let carsToMap = [];
      let carsToFilter = this.cars;
      const kindsOpenedAllowed = [
        'FIRST_LOCATION',
        'NEW_LOCATION',
        'PLAN_EXCHANGE',
        'DEFINITIVE_EXCHANGE_DESMOB',
        'DEFINITIVE_EXCHANGE_SINISTER',
        'DEFINITVE_EXCHANGE_SAFETY',
      ];

      if (flag === 'KOVI_PROPRIO_2' && kindsOpenedAllowed.includes(this.kindOpened.type)) {
        carsToFilter = this.getCarsFilteredByFlag();
      }

      carsToMap = category && category !== 'ALL' ? this.getCarsByCategory(carsToFilter, category) : carsToFilter;

      this.filteredCars = this.mappedCars(carsToMap);
    },
  },
};
</script>
