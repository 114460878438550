<template>
  <b-modal
    v-model="cpHandlerModal"
    lazy
    no-fade
    scrollable
    no-enforce-focus
    hide-footer
    size="lg"
    :title="$t('modalBooking.titles.areYouSure')"
    @show="getOptionsCancelBooking"
    @hidden="handleClose"
  >
    <content-loading v-if="loading" />
    <b-container
      v-else
      class="pt-none mt-none"
    >
      <div class="row">
        <div class="col-12 text-center">
          <h4>{{ $t('modalBooking.closingReason.title') }}</h4>
          <hr>
        </div>
      </div>
      <h5 class="text-center">
        {{ $t('modalBooking.titles.areaSelect') }}:
      </h5>
      <b-form-row class="justify-content-center">
        <b-form-group
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="btn-radios-2"
            v-model="groupSelect"
            :options="optionsGroup"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"
            name="radio-btn-outline"
            buttons
            size="md"
            @input="getOptionsClosing"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row class="justify-content-center">
        <div class="col-6">
          <b-form-group class="mb-3">
            <b-form-radio-group
              v-model="closeReason"
              :options="options"
              name="cancelOptions"
              class="pl-2 w-100"
              stacked
              size="lg"
            />
          </b-form-group>
        </div>
      </b-form-row>

      <div class="row mt-3" align-v="end">
        <div class="col-12">
          <div class="pt-2">
            <b-button
              variant="primary"
              class="ml-2 float-right"
              data-test="modal-transaction__btn-save"
              :disabled="!closeReason"
              @click="cancelTransaction()"
            >
              {{ $t('payments.buttons.confirm') }}
            </b-button>
            <b-button
              class="ml-2 float-right"
              data-test="modal-transaction__btn-save"
              @click="$emit('closeModalCancelTransaction')"
            >
              {{ $t('payments.buttons.cancel') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>


<script>
import ContentLoading from '@components/content-loading';
import CANCEL_BOOKING from '@graphql/booking/queries/get-booking-closing-kinds.gql';
import CLOSING_KINDS_SUGGESTION from '@graphql/booking/queries/get-booking-closing-kind-suggestion.gql';
import { makeQuery } from "@graphql/middleware";
import { isMexico } from '@utils/helper-region';
import { mapGetters } from 'vuex'
export default {
  name: 'ModalCancelBooking',
  components: {
    ContentLoading
  },
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
    modalData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      region: 'pt-br',
      closeReason: '',
      closeReasonSuggestion: '',
      groupSelect: '',
      options: [],
      optionsGroup: [],
      categorys: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      }
    },
  },
  mounted () {
    this.verifyIsMexico()
  },
  methods: {
    verifyIsMexico () {
      if(isMexico()) {
        this.region = 'es-mx'
      } else {
        this.region = 'pt-br'
      }
    },
    cancelTransaction () {
      if (!this.closeReason) {
       this.$swal({
          type: 'error',
          title: this.$t('payments.reasonCancel.erroCancelTransaction'),
          showConfirmButton: false,
          timer: 2500,
        });
        return
      }
      const reason = this.closingItems.find(item =>  item.type === this.closeReason)
      this.$emit('confirmCloseBooking', {
        text: reason.translate[this.region],
        value: reason.type,
        id: reason.id
      })
    },
    getOptionsCancelBooking () {
      this.loading = true
      makeQuery(
        CANCEL_BOOKING,
        { 
          page: 0,
          limit: 100
        }
      )
        .then((res) => {
          this.closingItems = res.data.getBookingClosingKinds.items
          this.getCategorys(this.closingItems)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
          this.getClosingKindsSuggestion()
        })
    },
    getClosingKindsSuggestion () {
      makeQuery(
        CLOSING_KINDS_SUGGESTION,
        { 
          driver: this.modalData.driver,
        }
      )
        .then((res) => {
          const suggestion = res.data.getBookingClosingKindSuggestion
          this.groupSelect = suggestion.category
          this.closeReasonSuggestion = suggestion.type
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getCategorys (arrayClosingItems) {
      this.categorys = arrayClosingItems.map(item => item.category).filter((elem, index, self) => {
        return index === self.indexOf(elem);
      })
      this.optionsGroup = this.categorys.map((item) => {
        return {
          text: this.$t(`modalBooking.enumGroupClosingReason.${item}`),
          value: item,
        }
      })
    },
    getOptionsClosing () {
      this.options = this.closingItems
        .filter(item =>  item.category === this.groupSelect)
        .map(item => {
          return {
            text: item.translate[this.region],
            value: item.type
          }
        })
      this.closeReason = this.closeReasonSuggestion || ''
      this.closeReasonSuggestion = ''
    },
    handleClose() {
      this.groupSelect = '';
      this.$emit('change', false);
    }
  }
}
</script>
