export const BookingOpeningKindEnum = {
  DEFINITIVE_EXCHANGE_DESMOB: 'DEFINITIVE_EXCHANGE_DESMOB',
  DEFINITIVE_EXCHANGE_MAINTENANCE: 'DEFINITIVE_EXCHANGE_MAINTENANCE',
  DEFINITIVE_EXCHANGE_SINISTER: 'DEFINITIVE_EXCHANGE_SINISTER',
  DEFINITVE_EXCHANGE_SAFETY: 'DEFINITVE_EXCHANGE_SAFETY',
  FIRST_LOCATION: 'FIRST_LOCATION',
  NEW_LOCATION: 'NEW_LOCATION',
  PLAN_EXCHANGE: 'PLAN_EXCHANGE',
  RESERVATION_DELIVER: 'RESERVATION_DELIVER',
  TITULAR_VEHICLE_DELIVER: 'TITULAR_VEHICLE_DELIVER',
};