var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "docsUpload",
        lazy: "",
        "no-fade": "",
        size: "lg",
        "hide-footer": true,
        title: _vm.$t("drivers.labels.deliveryCars"),
      },
      on: { hide: _vm.handleClose, show: _vm.handleShow },
    },
    [
      !!_vm.booking.driver.id
        ? _c("driver-header", {
            attrs: { id: _vm.booking.driver.id, "reset-profile": false },
          })
        : _vm._e(),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _vm._v(" " + _vm._s(_vm.booking.plan.name) + " "),
              _c("br"),
              _vm.booking.car.fuel.length
                ? _c(
                    "div",
                    _vm._l(_vm.booking.car.fuel, function (obj, index) {
                      return _c("strong", { key: index }, [
                        _vm._v(" " + _vm._s(_vm.getFuelName(obj)) + " "),
                        _c("span", [_vm._v("/")]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(" " + _vm._s(_vm.$t("drivers.labels.board")) + " "),
              _c("br"),
              _c("strong", [
                _vm._v(" " + _vm._s(_vm.booking.car.license_plate) + " "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mb-4" }, [
              _c("h5", [
                _c("strong", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("drivers.labels.docsLocation")) + " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "div",
                { key: index, class: _vm.isBrazil ? "col-4" : "col-6 mb-2" },
                [
                  _c("new-upload-image", {
                    attrs: {
                      label: item.label,
                      caption: item.caption,
                      item: item,
                      "key-item": item.keyItem,
                      "file-upload-endpoint": item.endpoint,
                      "file-upload-props": item.uploadProps,
                    },
                    on: {
                      imageUpload: function ($event) {
                        return _vm.onImageUpload($event)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "footer row" }, [
        _c(
          "div",
          { staticClass: "col-12 d-flex justify-content-end" },
          [
            _c(
              "b-button",
              { staticClass: "close-btn", on: { click: _vm.handleClose } },
              [_vm._v(" " + _vm._s(_vm.$t("modalBooking.words.close")) + " ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }