<template>
  <section class="upload-image">
    <b-modal
      id="onDocsUpload"
      lazy
      no-fade
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      header-bg-variant="white"
      size="md"
      :hide-footer="true"
      title="Atualização da Data de Validade da CNH"
    >
      <p>Observamos uma divergência na data de validade em relação à imagem enviada. Por favor, confirme a data de validade da CNH:</p>
      <b-form>
        <label for="license_expired_at" class="font-weight-bold">Data vencimento:</label>
        <b-form-input id="license_expired_at" v-model="form.license_expired_at" type="date" />
        <b-button class="mt-3" block variant="warning" @click="changeCNHExpirationDate"> Confirmar </b-button>
      </b-form>
    </b-modal>
    <!-- Upload Image -->
    <input ref="inputFile" type="file" :accept="this.item.accept || 'image/*'" @change="onFileSelected($event)" />
    <div v-if="!loading && !image" class="wrapper-image" @click="$refs.inputFile.click()" @drop.prevent="addFileDrop($event)" @dragover.prevent>
      <div>
        <i class="fa fa-camera font-5xl text-secondary" />
      </div>
      <p class="label uppercase">
        {{ label }}
      </p>
    </div>
    <!-- /Upload Image -->

    <!-- Loading Image -->
    <div v-else-if="image && loading" :class="[{ 'progress-content': loading && image }, 'wrapper-image']">
      <b-progress :value="valueProgress" :max="maxProgress" show-progress animated :variant="variant">
        <b-progress-bar :value="valueProgress">
          <span>{{ titleProgress }} <span v-if="titleProgress">-</span> {{ valueProgress }}%</span>
        </b-progress-bar>
      </b-progress>
    </div>
    <!-- /Loading Image -->

    <!-- Preview Image -->
    <div v-else-if="image || imageUrl" class="wrapper-image preview">
      <img :src="image || imageUrl" class="img-fluid" />
    </div>

    <p v-if="caption" class="label caption">
      {{ caption }}
    </p>
    <!-- /Preview Image -->

    <b-button class="bg-warning mt-2" :disabled="disableBtn" @click="$refs.inputFile.click()">
      {{ buttonText }}
    </b-button>
  </section>
</template>

<script>
import { readFile, compressImage } from '@utils/image';
import UPDATE_BOOKING_CONTRACT from '@graphql/driver-documents/mutations/update-booking-contract.gql';
import UPDATE_CAR_DOCUMENT from '@graphql/driver-documents/mutations/update-car-document.gql';
import APPROVE_DOCUMENTATION from '@graphql/driver-documents/mutations/new-approve-documentation.gql';
import UPDATE_DRIVER_CNH from '@graphql/driver-documents/mutations/update-cnh-doc-driver.gql';
import UPDATE_LICENSE_EXPIRED_AT from '@graphql/driver/mutations/update-license-expired-at.gql';
const { parse, isBefore } = require('date-fns');
import gql from 'graphql-tag';

export default {
  name: 'NewUploadImage',

  props: {
    label: {
      default: 'Arquivo',
    },

    caption: {
      default: 'Clique na caixa',
    },

    item: {
      default: null,
    },

    keyItem: {
      default: 'keyItem',
    },

    fileUploadEndpoint: {
      default: '/docs/upload-generic',
    },

    fileUploadProps: {
      default: {},
    },
  },

  data() {
    return {
      image: null,
      imageUrl: null,
      loading: null,
      file: null,
      disableBtn: true,
      format: 'HH:mm DD/MM/YYYY',
      buttonText: 'Enviar Documento',
      valueProgress: 0,
      maxProgress: 100,
      variant: null,
      imageId: null,
      titleProgress: null,
      form: {
        license_expired_at: '',
      },
    };
  },
  apollo: {
    driver: {
      query: gql`
        query DriverGET($id: ID!) {
          driver(id: $id) {
            id
            license_expired_at
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update(data) {
        const { driver } = data;
        return driver;
      },
    },
  },
  created() {
    this.checkItem();
  },

  methods: {
    addFileDrop($event) {
      this.file = $event.dataTransfer.files[0];
      this.onUpload();
    },
    async transformPdfToImage(pdfBase64File, currentProps, config) {
      const axios = require('axios').default;
      this.titleProgress = 'convertendo';

      const formData = new FormData();
      formData.append('data', pdfBase64File.split(',')[1]);
      formData.append('documentType', 'cnh_pdf');
      formData.append('driverId', currentProps.driverId || 'driverId');
      const res = await axios.post(`${process.env.KOVI_API_URL}${this.fileUploadEndpoint}`, formData, config).then(response => response.data);

      this.loading = false;
      this.titleProgress = null;
      return `data:image/png;base64,${res.image}`;
    },

    onFileSelected($event) {
      this.file = $event.target.files[0];
      this.onUpload();
    },

    async onUpload() {
      let base64File = await readFile(this.file);
      let base64FileOriginalPdf = null;
      this.loading = true;
      this.variant = 'success';

      this.$emit('imageUpload', {
        key: this.keyItem,
        msg: 'Carregando...',
      });

      const config = {
        onUploadProgress: progressEvent => {
          this.valueProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        },
      };

      if (this.file.type === 'application/pdf') {
        base64FileOriginalPdf = base64File;
        base64File = await this.transformPdfToImage(base64File, this.fileUploadProps, config);

        if (!base64File) {
          this.loading = false;
          return;
        }
        this.image = base64File;
      } else {
        this.image = await compressImage(base64File);
      }

      const keys = Object.keys(this.fileUploadProps);
      let fd = new FormData();
      fd.append(this.fileUploadProps.fileKey, this.image);
      fd.append(keys[0], this.fileUploadProps[keys[0]]);
      fd.append(keys[1], this.fileUploadProps[keys[1]]);
      if (base64FileOriginalPdf) {
        fd.append('originalPdf', base64FileOriginalPdf);
      }

      this.titleProgress = 'subindo';
      const axios = require('axios').default;
      this.loading = true;

      axios
        .post(`${process.env.KOVI_API_URL}${this.fileUploadEndpoint}`, fd, config)
        .then(response => {
          this.imageUrl = this.fileUploadProps.fileKey == 'data' ? response.data.body.url : response.data.body.absolute_path;
          this.imageId = this.fileUploadProps.fileKey == 'data' ? response.data.body.id : null;
          this.saveUrl();
        })
        .catch(err => {
          console.log(err);
          this.variant = 'danger';
          this.valueProgress = 99;
          this.$emit('imageUpload', {
            key: this.keyItem,
            msg: 'Erro!',
          });
        })
        .finally(() => {
          this.titleProgress = null;
        });
    },

    saveUrl() {
      const itemKey = this.fileUploadProps.type || this.fileUploadProps.documentType;
      let source = this.fileUploadProps.source || this.fileUploadProps.driverId;
      let MUTATION;
      let VARIABLES;

      if (itemKey === 'contract') {
        MUTATION = UPDATE_BOOKING_CONTRACT;
        VARIABLES = {
          input: {
            booking: source,
            contract: this.imageUrl,
          },
        };
      } else if (itemKey === 'cnh_photo') {
        MUTATION = APPROVE_DOCUMENTATION;
        VARIABLES = {
          documentId: this.imageId,
        };
      } else if (itemKey === 'address_photo') {
        MUTATION = APPROVE_DOCUMENTATION;
        VARIABLES = {
          documentId: this.imageId,
        };
      } else if (itemKey === 'car_document') {
        MUTATION = UPDATE_CAR_DOCUMENT;
        VARIABLES = {
          input: {
            car: source,
            document_url: this.imageUrl,
          },
        };
      }

      return this.$apollo
        .mutate({
          mutation: MUTATION,
          variables: VARIABLES,
        })
        .then(response => {
          if (MUTATION === APPROVE_DOCUMENTATION && this.keyItem === 'cnh') {
            return this.$apollo
              .mutate({
                mutation: UPDATE_DRIVER_CNH,
                variables: {
                  driverId: this.fileUploadProps.driverId,
                  documentId: this.imageId,
                },
              })
              .then(response => {
                this.loading = false;
                this.valueProgress = 0;
                this.disableBtn = false;
                this.buttonText = this.$t('drivers.labels.docsUpdate');
                this.$emit('imageUpload', {
                  key: this.keyItem,
                  value: this.image,
                  msg: this.$t('drivers.labels.docsUpdated') + ' ' + this.$moment().format(this.format),
                });
                const cnhExpired = isBefore(parse(this.driver.license_expired_at, 'yyyy-MM-dd', new Date()), new Date());
                if (this.keyItem === 'cnh' && cnhExpired) {
                  this.$root.$emit('bv::show::modal', 'onDocsUpload');
                }
              })
              .catch(() => {
                return Promise.reject('Não foi possível finalizar o processo.');
              });
          } else {
            this.loading = false;
            this.valueProgress = 0;
            this.disableBtn = false;
            this.buttonText = this.$t('drivers.labels.docsUpdate');
            this.$emit('imageUpload', {
              key: this.keyItem,
              value: this.image,
              msg: this.$t('drivers.labels.docsUpdated') + ' ' + this.$moment().format(this.format),
            });
          }
        })
        .catch(() => {
          return Promise.reject('Não foi possível finalizar o processo.');
        });
    },

    checkItem() {
      if (this.item.item != null) {
        this.disableBtn = false;
        this.buttonText = this.$t('drivers.labels.docsUpdate');
        this.image = `${this.item.item}?t${+new Date()}`;
        this.$emit('imageUpload', {
          key: this.keyItem,
          value: this.item.item,
          msg: 'Clique no botão para atualizar.',
        });
      }
    },

    async changeCNHExpirationDate() {
      this.$root.$emit('bv::hide::modal', 'onDocsUpload');
      await this.handleGqlUpdateLicenseExpiredAt();
    },

    async handleGqlUpdateLicenseExpiredAt() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LICENSE_EXPIRED_AT,
          variables: {
            driverId: this.$route.params.id,
            licenseExpiredAt: this.form.license_expired_at,
          },
        });
        this.$swal({
          type: 'success',
          title: 'Data de validade da CNH atualizada com sucesso',
          showConfirmButton: false,
          timer: 2500,
        });
      } catch (err) {
        this.$swal({
          type: 'error',
          title: 'Falha ao atualizar a Data de validade da CNH',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-image {
  input {
    display: none;
  }

  .wrapper-image {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: 3px dashed #b3b3b3;
    max-height: 125px;
    height: 125px;

    &.preview {
      padding: 0;
      border: 0;
      cursor: default;

      img {
        max-height: 125px;
        height: 125px;
      }
    }

    &.progress-content {
      border: 0;

      .progress {
        background: transparent;
      }
    }
  }

  button {
    width: 100%;
  }

  .label {
    color: #979797;
    margin: 10px 0;

    &.caption {
      font-size: 12px;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
}
</style>
