var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "upload-image" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "onDocsUpload",
            lazy: "",
            "no-fade": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            "header-bg-variant": "white",
            size: "md",
            "hide-footer": true,
            title: "Atualização da Data de Validade da CNH",
          },
        },
        [
          _c("p", [
            _vm._v(
              "Observamos uma divergência na data de validade em relação à imagem enviada. Por favor, confirme a data de validade da CNH:"
            ),
          ]),
          _c(
            "b-form",
            [
              _c(
                "label",
                {
                  staticClass: "font-weight-bold",
                  attrs: { for: "license_expired_at" },
                },
                [_vm._v("Data vencimento:")]
              ),
              _c("b-form-input", {
                attrs: { id: "license_expired_at", type: "date" },
                model: {
                  value: _vm.form.license_expired_at,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "license_expired_at", $$v)
                  },
                  expression: "form.license_expired_at",
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { block: "", variant: "warning" },
                  on: { click: _vm.changeCNHExpirationDate },
                },
                [_vm._v(" Confirmar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        ref: "inputFile",
        attrs: { type: "file", accept: this.item.accept || "image/*" },
        on: {
          change: function ($event) {
            return _vm.onFileSelected($event)
          },
        },
      }),
      !_vm.loading && !_vm.image
        ? _c(
            "div",
            {
              staticClass: "wrapper-image",
              on: {
                click: function ($event) {
                  return _vm.$refs.inputFile.click()
                },
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.addFileDrop($event)
                },
                dragover: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm._m(0),
              _c("p", { staticClass: "label uppercase" }, [
                _vm._v(" " + _vm._s(_vm.label) + " "),
              ]),
            ]
          )
        : _vm.image && _vm.loading
        ? _c(
            "div",
            {
              class: [
                { "progress-content": _vm.loading && _vm.image },
                "wrapper-image",
              ],
            },
            [
              _c(
                "b-progress",
                {
                  attrs: {
                    value: _vm.valueProgress,
                    max: _vm.maxProgress,
                    "show-progress": "",
                    animated: "",
                    variant: _vm.variant,
                  },
                },
                [
                  _c(
                    "b-progress-bar",
                    { attrs: { value: _vm.valueProgress } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.titleProgress) + " "),
                        _vm.titleProgress
                          ? _c("span", [_vm._v("-")])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.valueProgress) + "%"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.image || _vm.imageUrl
        ? _c("div", { staticClass: "wrapper-image preview" }, [
            _c("img", {
              staticClass: "img-fluid",
              attrs: { src: _vm.image || _vm.imageUrl },
            }),
          ])
        : _vm._e(),
      _vm.caption
        ? _c("p", { staticClass: "label caption" }, [
            _vm._v(" " + _vm._s(_vm.caption) + " "),
          ])
        : _vm._e(),
      _c(
        "b-button",
        {
          staticClass: "bg-warning mt-2",
          attrs: { disabled: _vm.disableBtn },
          on: {
            click: function ($event) {
              return _vm.$refs.inputFile.click()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fa fa-camera font-5xl text-secondary" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }