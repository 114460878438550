export const PlanStatusEnum = {
  ACTIVE: 'ACTIVE',
  RETIRED: 'RETIRED',
  INACTIVE: 'INACTIVE',
  PAUSED: 'PAUSED',
}

export const PlanProductTypesEnum = {
  KOVI_PROPRIO: 'KOVI_PROPRIO',
  KOVI_PROPRIO_2: 'KOVI_PROPRIO_2',
  KOVI_MENSAL: 'KOVI_MENSAL',
  KOVI_ANUAL: 'KOVI_ANUAL',
}
export const PlanProductEnum = {
  RENT_TO_OWN: 'RENT_TO_OWN',
  RENTAL: 'RENTAL',
}

export const PlanCatergoriesEnum = {
  SEDAN_PREMIUM: 'SEDAN_PREMIUM',
  SEDAN_BASICO: 'SEDAN_BASICO',
  HATCH_BASICO: 'HATCH_BASICO',
  HATCH_ECONOMICO: 'HATCH_ECONOMICO',
  HATCH_COMFORT: 'HATCH_COMFORT',
  SUV_BASICO: 'SUV_BASICO',
  OTHERS: 'OTHERS',
}

export const TransactionCycleEnum = {
  WEEKLY: 7,
  BIWEEKLY: 14,
  MONTHLY: 30,
}

export const MinimumPeriodContractEnum = {
  WEEKLY: 7,
  MONTHLY: 30,
  ANNUAL: 365,
}

export const BillingTypeEnum = {
  PRE_PAY: 'PRE_PAY',
  POS_PAY: 'POS_PAY',
}
