var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        lazy: "",
        "no-fade": "",
        scrollable: "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "lg",
        title: _vm.$t("modalBooking.titles.areYouSure"),
      },
      on: { show: _vm.getOptionsCancelBooking, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _vm.loading
        ? _c("content-loading")
        : _c(
            "b-container",
            { staticClass: "pt-none mt-none" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("modalBooking.closingReason.title"))),
                  ]),
                  _c("hr"),
                ]),
              ]),
              _c("h5", { staticClass: "text-center" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("modalBooking.titles.areaSelect")) + ": "
                ),
              ]),
              _c(
                "b-form-row",
                { staticClass: "justify-content-center" },
                [
                  _c("b-form-group", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ ariaDescribedby }) {
                          return [
                            _c("b-form-radio-group", {
                              attrs: {
                                id: "btn-radios-2",
                                options: _vm.optionsGroup,
                                "aria-describedby": ariaDescribedby,
                                "button-variant": "outline-primary",
                                name: "radio-btn-outline",
                                buttons: "",
                                size: "md",
                              },
                              on: { input: _vm.getOptionsClosing },
                              model: {
                                value: _vm.groupSelect,
                                callback: function ($$v) {
                                  _vm.groupSelect = $$v
                                },
                                expression: "groupSelect",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("b-form-row", { staticClass: "justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "b-form-group",
                      { staticClass: "mb-3" },
                      [
                        _c("b-form-radio-group", {
                          staticClass: "pl-2 w-100",
                          attrs: {
                            options: _vm.options,
                            name: "cancelOptions",
                            stacked: "",
                            size: "lg",
                          },
                          model: {
                            value: _vm.closeReason,
                            callback: function ($$v) {
                              _vm.closeReason = $$v
                            },
                            expression: "closeReason",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "row mt-3", attrs: { "align-v": "end" } },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "pt-2" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-2 float-right",
                            attrs: {
                              variant: "primary",
                              "data-test": "modal-transaction__btn-save",
                              disabled: !_vm.closeReason,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.cancelTransaction()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("payments.buttons.confirm")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-2 float-right",
                            attrs: {
                              "data-test": "modal-transaction__btn-save",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("closeModalCancelTransaction")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("payments.buttons.cancel")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }