var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: "notify-box " + this.size }, [
    this.service.errorType === "Error"
      ? _c("div", [
          _vm._m(0),
          _c(
            "h5",
            [
              _vm._t("error", function () {
                return [_vm._v(" Ocorreu um erro ao carregar esta página :( ")]
              }),
            ],
            2
          ),
        ])
      : _c("div", [
          _vm._m(1),
          _c(
            "h5",
            [
              _vm._t("default", function () {
                return [_vm._v("Ainda não temos nenhuma informação aqui...")]
              }),
            ],
            2
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("i", { staticClass: "fa fa-unlink" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("i", { staticClass: "fa fa-thumbs-o-down" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }