export const formatStatus = status => {
  return status === 'ACTIVE'
    ? 'success'
    : status === 'RETIRED'
      ? 'secondary'
      : status === 'MAINTENANCE_OPS'
        ? 'warning'
        : status === 'MAINTENANCE_CRASH'
          ? 'danger'
          : 'primary'
}

export const enumStatus = [
  { text: 'Ativo', value: 'ACTIVE' },
  { text: 'Manutenção', value: 'MAINTENANCE_OPS' },
  { text: 'Sinistro', value: 'MAINTENANCE_CRASH' },
  { text: 'Arquivado', value: 'RETIRED' },
]
