<script>
export default {
  name: 'ContentControll',
  props: {
    service: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'regular',
    },
  },
}
</script>

<template>
  <div :class="'notify-box ' + this.size">
    <div v-if="this.service.errorType === 'Error'">
      <h1><i class="fa fa-unlink" /></h1>
      <h5>
        <slot name="error">
          Ocorreu um erro ao carregar esta página :(
        </slot>
      </h5>
    </div>
    <div v-else>
      <h1><i class="fa fa-thumbs-o-down" /></h1>
      <h5>
        <slot>Ainda não temos nenhuma informação aqui...</slot>
      </h5>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.regular {
  text-align: center;
  padding: 60px 0;
  h1 {
    font-size: 40px;
  }
  h5 {
    margin-top: 30px;
  }
}
.small {
  text-align: center;
  padding: 10px 0;
  h1 {
    font-size: 30px;
  }
  h5 {
    margin-top: 10px;
  }
}
</style>
