<template>
  <b-modal id="docsUpload" lazy no-fade size="lg" :hide-footer="true" :title="$t('drivers.labels.deliveryCars')" @hide="handleClose" @show="handleShow">
    <driver-header v-if="!!booking.driver.id" :id="booking.driver.id" :reset-profile="false" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            {{ booking.plan.name }}

            <br />
            <div v-if="booking.car.fuel.length">
              <strong v-for="(obj, index) in booking.car.fuel" :key="index">
                {{ getFuelName(obj) }}
                <span>/</span>
              </strong>
            </div>
          </div>

          <div class="col-8">
            {{ $t('drivers.labels.board') }}

            <br />
            <strong>
              {{ booking.car.license_plate }}
            </strong>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-4">
            <h5>
              <strong>
                {{ $t('drivers.labels.docsLocation') }}
              </strong>
            </h5>
          </div>
        </div>

        <div class="row">
          <div v-for="(item, index) in images" :key="index" :class="isBrazil ? 'col-4' : 'col-6 mb-2'">
            <new-upload-image
              :label="item.label"
              :caption="item.caption"
              :item="item"
              :key-item="item.keyItem"
              :file-upload-endpoint="item.endpoint"
              :file-upload-props="item.uploadProps"
              @imageUpload="onImageUpload($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="footer row">
      <div class="col-12 d-flex justify-content-end">
        <b-button class="close-btn" @click="handleClose">
          {{ $t('modalBooking.words.close') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DriverHeader from '@components/drivers/header';
import NewUploadImage from '@components/shared/NewUploadImage';
import { mapGetters } from 'vuex';
import { isBrazil as isBrazilHelper } from '@utils/helper-region';

export default {
  name: 'ModalDocsUpload',

  components: {
    DriverHeader,
    NewUploadImage,
  },
  data() {
    return {
      booking: {
        car: {
          fuel: [],
          license_plate: '',
        },
        contract: '',
        driver: {
          id: '',
        },
        id: '',
        plan: {
          name: '',
        },
      },
      addressProof: null,
    };
  },
  computed: {
    ...mapGetters({
      bookingSelect: 'driver/bookingSelect',
      driverDocuments: 'driverDocument/address',
    }),
    isBrazil() {
      return isBrazilHelper();
    },
    images() {
      const uploadImagesProps = {
        contrato: {
          label: 'contrato',
          keyItem: 'contrato',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.contract || null,
          endpoint: '/docs/upload-generic',
          uploadProps: {
            type: 'contract',
            source: this.bookingSelect?.id || null,
            fileKey: 'file',
          },
        },
        cnh: {
          label: this.$t('drivers.labels.license_number'),
          keyItem: 'cnh',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.driver?.cnh_doc?.url || null,
          endpoint: '/docs',
          accept: 'image/*,.pdf',
          uploadProps: {
            documentType: 'cnh_photo',
            driverId: this.bookingSelect?.driver?.id || null,
            fileKey: 'data',
          },
        },
        crlv: {
          label: 'crlv',
          keyItem: 'crlv',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.car?.document_url || null,
          endpoint: '/docs/upload-generic',
          uploadProps: {
            type: 'car_document',
            source: this.bookingSelect?.car?.id || null,
            fileKey: 'file',
          },
        },
        address: {
          label: this.$t('drivers.labels.addressProof'),
          keyItem: 'address_photo',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.addressProof?.url || null,
          endpoint: '/docs',
          accept: 'image/*,.pdf',
          uploadProps: {
            documentType: 'address_photo',
            driverId: this.bookingSelect?.driver?.id || null,
            fileKey: 'data',
          },
        },
      };

      if (this.isBrazil) delete uploadImagesProps.crlv;

      return uploadImagesProps;
    },
  },
  mounted() {
    this.addressProof = this.driverDocuments?.find(item => item.type === 'ADDRESS');
  },
  methods: {
    handleShow() {
      this.booking = this.bookingSelect;
    },
    handleClose() {
      this.$root.$emit('bv::hide::modal', 'docsUpload');
      this.$root.$emit('bv::hide::modal', 'modalBooking');
      window.location.reload();
    },
    onImageUpload($event) {
      this.images[$event.key].caption = $event.msg;
    },
    getFuelName(fuel) {
      return fuel?.name || '';
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  &.close-btn {
    min-width: 135px;
  }
}
</style>
