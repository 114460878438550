export function subtractMonthAndYear(date1, date2) {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();

  const totalMonths1 = year1 * 12 + month1;
  const totalMonths2 = year2 * 12 + month2;

  return totalMonths1 - totalMonths2;
}
