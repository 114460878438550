<template>
  <b-col>
    <b-form-group>
      <div v-if="lastClosedBookingKind != null">
        <b-card :title="$i18n.t('modalBooking.texts.lastBookingResume')">
          <b-card-text>
            <strong>{{ $i18n.t('modalBooking.texts.lastClosedBookingResume.kind') }}:</strong> <br />
            {{ lastClosedBookingKind.translate[region] }}
          </b-card-text>
          <b-card-text>
            <strong>{{ $i18n.t('modalBooking.texts.lastClosedBookingResume.finishedDate') }}: </strong>
            <br />
            {{ dateFormat(lastClosedBookingKind.finished_at) }}
          </b-card-text>
          <b-card-text>
            <strong>{{ $i18n.t('modalBooking.texts.lastClosedBookingResume.finishedInDays') }}: </strong>
            <br />
            {{ getDaysFrom(lastClosedBookingKind.finished_at) }}
          </b-card-text>
          <b-card-text>
            <strong>{{ $i18n.t('modalBooking.texts.lastClosedBookingResume.plan') }}: </strong>
            <br />
            {{ lastClosedBookingKind.plan.name }}
            <br />
            {{ lastClosedBookingKind.plan.description }}
          </b-card-text>
          <br />

          <b-form-checkbox
            v-if="useLastPlan && lastClosedBookingKind.plan.status !== 'RETIRED'"
            id="checkbox-use-lastplan"
            v-model="checkedValue"
            name="checkbox-use-lastplan"
            :value="lastClosedBookingKind.plan"
            :unchecked-value="{}"
          >
            {{ $i18n.t('modalBooking.texts.useLastBookingPlan') }}
          </b-form-checkbox>
        </b-card>
      </div>
      <b-card v-else>
        <b-card-text>
          {{ $i18n.t('modalBooking.texts.driverHaveNotPreviousBooking') }}
        </b-card-text>
      </b-card>
    </b-form-group>
  </b-col>
</template>
<script>
import { formatDate, daysFrom } from '@utils/formatters/date';
import LAST_CLOSED_BOOKING_DRIVER from '@graphql/booking/queries/last-closed-booking-driver.gql';

export default {
  components: {},
  props: {
    driverId: {
      type: String,
      required: true,
      default: null,
    },
    region: {
      type: String,
      required: true,
      default: 'BR',
    },
    useLastPlan: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      lastClosedBookingKind: null,
      checkedValue: '',
    };
  },
  apollo: {
    previous_booking: {
      query: LAST_CLOSED_BOOKING_DRIVER,
      variables() {
        return {
          driver: this.driverId,
        };
      },
      update: data => data,
      result(data) {
        if (!data.loading) {
          this.setLastClosedBookingKind(data.data.lastClosedBookingByDriver);
        }
      },
    },
  },
  watch: {
    checkedValue() {
      this.$emit('selected', this.checkedValue);
    },
  },
  methods: {
    dateFormat(date) {
      return formatDate(date);
    },
    getDaysFrom(date) {
      const total = daysFrom(date);
      if (total > 1) {
        return `${total} dias`;
      }
      return `${total} dia`;
    },
    setLastClosedBookingKind({ plan, kind_closed_id, finished_at, closingKind: { type, translate } }) {
      this.lastClosedBookingKind = {
        id: kind_closed_id,
        type,
        translate,
        finished_at,
        plan,
      };
    },
  },
};
</script>
