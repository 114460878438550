var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("multiselect", {
        staticClass: "mb-1",
        attrs: {
          label: "text",
          placeholder: "Selecione um carro",
          "track-by": "value",
          "close-on-select": true,
          disabled: !_vm.filteredCars.length || _vm.disabled,
          multiple: false,
          options: _vm.filteredCars,
          value: _vm.findSelectedItem,
        },
        on: { input: _vm.emitValue },
      }),
      _c("b-alert", { attrs: { show: _vm.displayAlert, variant: "warning" } }, [
        _vm._v("Esse carro não pertence ao plano selecionado"),
      ]),
      _c("b-alert", [_vm._v(_vm._s(_vm.kindOpened))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }